<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>粉丝会员</el-breadcrumb-item>
          <el-breadcrumb-item>会员列表</el-breadcrumb-item>
          <el-breadcrumb-item>会员详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row :gutter="40">
        <el-col class="header" :span="12">
          <el-row :gutter="40">
            <el-col style="width: 90px">
              <el-avatar
                :size="70"
                :src="getIconUrl(query.headImageUrl)"
              ></el-avatar>
            </el-col>
            <el-col :span="18" style="line-height: 26px">
              <el-row>
                <el-col class="name">{{ query.nickName }}</el-col>
                <el-col>账号：{{ query.mobile }}</el-col>
                <el-col>加入时间：{{ query.createDate }}</el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基本信息" name="first">
              <detail :id="query.id" v-if="detailIs"></detail>
            </el-tab-pane>
            <el-tab-pane label="收益明细" name="earnings">
              <earnings :id="query.id" v-if="earningsIs"></earnings>
            </el-tab-pane>
            <el-tab-pane label="消费/惠豆明细" name="huidou">
              <huidou :id="query.id" v-if="huidouIs"></huidou>
            </el-tab-pane>
            <el-tab-pane label="粉丝明细" name="fans">
              <fans :id="query.id" v-if="fansIs"></fans>
            </el-tab-pane>
            <el-tab-pane label="兑换订单" name="order">
              <order :id="query.id" v-if="orderIs"></order>
            </el-tab-pane>
            <el-tab-pane label="提现明细" name="withdraw">
              <withdraw :id="query.id" v-if="withdrawIs"></withdraw>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import detail from "@/components/admin/members/user/detail.vue";
import earnings from "@/components/admin/members/user/earnings.vue";
import fans from "@/components/admin/members/user/fans.vue";
import order from "@/components/admin/members/user/order.vue";
import huidou from "@/components/admin/members/user/huidou.vue";
import withdraw from "@/components/admin/members/user/withdraw.vue";
import { staticUrl } from "@/plugins/api";

export default {
  components: {
    detail,
    earnings,
    fans,
    order,
    huidou,
    withdraw,
  },
  data() {
    return {
      infoDetail: {},
      id: 0,
      query: {},
      activeName: "first",
      /*定义前一个活动Tab,初始化默认为 ActiveTab */
      oldTab: "add",
      /*定义Tab是否加载-第一个默认加载 */
      detailIs: true,
      earningsIs: false,
      fansIs: false,
      orderIs: false,
      huidouIs: false,
      withdrawIs: false,
    };
  },
  methods: {
    handleClick(tab) {
      this[tab.name + "Is"] = true;
      if (tab.name != this.oldTab) {
        this[this.oldTab + "Is"] = false;
        this.oldTab = tab.name;
      }
    },
    getIconUrl(val) {
      return staticUrl + val;
    },
  },
  created() {
    this.query = this.$route.query;
  },
};
</script>
<style lang="scss" scoped>
.header {
  padding-top: 15px;
  padding-bottom: 15px;
}
.name {
  font-size: 18px;
  font-weight: bold;
}
.header.el-col {
  font-size: 14px;
}
</style>
