<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="类型">
            <el-select v-model="where.queryType" placeholder="类型">
              <el-option label="全部" value="0"></el-option>
              <el-option label="收益明细" value="1"></el-option>
              <el-option label="提现明细" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间">
            <el-date-picker
              v-model="date"
              @change="orderDate" @input="immediUpdate"
              type="monthrange"
              range-separator="~"
              value-format="yyyy-MM"
              format="yyyy-MM"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
              <i class="el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
              >条件筛选</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="admin_main_block_right">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="dialogVisible = true"
              >修改余额
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" disabled icon="el-icon-tickets"
              >导出数据
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        prop="orderNo"
        label="交易/提现编号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="consumeId"
        label="粉丝ID"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="consumeNick"
        label="粉丝账号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="shopName"
        label="消费门店"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="expenditureAmount"
        label="消费金额"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="givingPercent"
        label="赠豆比例"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop=""
        label="分佣金额"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop=""
        label="类型"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop=""
        label="结余"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="createDate"
        label="操作时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="说明"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
        @current-change="currentChange"
        @size-change="handleSizeChange"
        background
        :total="totalData"
        :page-size="pageSize"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
    <dialogs @dialog="getDialog" v-if="dialogVisible" :id="id"></dialogs>
  </div>
</template>
<script>
import dialogs from "@/components/admin/members/user/editMoney.vue";
export default {
  components: {
    dialogs,
  },
  props: {
    id: String,
  },
  data() {
    return {
      list: [],
      date: "",
      userInfo: "",
      dialogVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      where: {
        pageNum: 0,
        pageSize: 0,
        shopId: 0,
        areaCode: "",
        consumeId: 0,
        endDate: "",
        queryType: "",
        startDate: "",
        userId: 0,
        userRoleId: 0,
      },
    };
  },
  methods: {
    listData() {
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.where.shopId = this.shopId;
      this.$post(this.$api.fenRunEarnings, this.where).then((res) => {
        if (res.code == 1 && res.data) {
          this.totalData = res.data.data.total;
          this.where.pageNum = res.data.data.pages;
          this.list = res.data.data.records;
        }
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startDate = '';
          this.where.endDate = '';
        }
      });
    },
    exportData() {
      // console.log("exportData");
    },
    getDialog(val) {
      this.dialogVisible = val;
    },
  },
  created() {
    this.listData();
  },
};
</script>
