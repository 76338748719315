<template>
  <div class="app">
    <el-dialog
      title="修改余额(*此操作影响较大，请谨慎操作！)"
      :visible.sync="dialogSendVisible"
      width="30%"
      :before-close="handleSendClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item
          label="操作人"
          prop="name"
          :rules="[
            { required: true, message: '请填写操作人', trigger: 'blur' },
          ]"
        >
          <el-input
            type="text"
            v-model="form.name"
            disabled
            maxlength="100"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="修改金额"
          prop="phone"
          :rules="[
            { required: true, message: '请填写修改金额', trigger: 'blur' },
          ]"
        >
          <el-input
            type="number"
            placeholder="减少余额请输入负数，如-1"
            v-model="form.phone"
            maxlength="10"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="修改原因"
          prop="address"
          :rules="[
            { required: true, message: '请填写修改原因', trigger: 'blur' },
          ]"
        >
          <el-input
            type="textarea"
            v-model="form.address"
            maxlength="200"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary" @click="send('form')">确定修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
  },
  data() {
    return {
      formCancel: "",
      form: {
        name: "",
        address: "",
        phone: "",
      },
      dialogSendVisible: true,
      formSend: "",
      logisticsInfos: {},
    };
  },
  methods: {
    send(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const postData = {};
              this.post("", postData);
              this.handleSendClose();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    sendDialog() {
      let userInfo = JSON.parse(localStorage.getItem("user_info"));
      this.form.name = userInfo.account;
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          // this.$router.go(-1);
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
    getUserinfo() {
      // this.$get("", { id: this.id }).then((res) => {
      //   console.log(res);
      // });
    },
  },
  created() {
    this.sendDialog();
  },
};
</script>
