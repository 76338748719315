<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="时间">
            <el-date-picker
              v-model="date"
              @change="orderDate" @input="immediUpdate"
              type="monthrange"
              range-separator="~"
              value-format="yyyy-MM"
              format="yyyy-MM"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
              <i class="el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
              >条件筛选</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        prop="shopId"
        label="会员头像"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-avatar
            :size="40"
            :src="getIconUrl(scope.row.headImageUrl)"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
        prop="shopId"
        label="粉丝会员ID"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="fansPhone"
        label="粉丝账号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="fansUserRealName"
        label="粉丝昵称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="shopId"
        label="消费笔数"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="shopId"
        label="消费金额"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="shopId"
        label="贡献总金额"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="lockDate"
        label="成为粉丝时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="shopId" label="操作" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-tag type="success" @click="fansDetail(scope.row.fansUserId)"
            >详情</el-tag
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
        @current-change="currentChange"
        @size-change="handleSizeChange"
        background
        :total="totalData"
        :page-size="pageSize"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
    <el-dialog title="贡献总金额：0.00" :visible.sync="dialogTableVisible">
      <el-table :data="gridData">
        <el-table-column
          property=""
          label="交易编号"
          width="150"
        ></el-table-column>
        <el-table-column
          property="date"
          label="交易时间"
          width="200"
        ></el-table-column>
        <el-table-column property="" label="消费金额"></el-table-column>
        <el-table-column property="" label="赠豆比例"></el-table-column>
        <el-table-column property="" label="贡献金额"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { staticUrl } from "@/plugins/api";

export default {
  components: {},
  props: {
    shopId: String,
  },
  data() {
    return {
      list: [],
      date: "",
      dialogTableVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      gridData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      where: {
        pageNum: 0,
        pageSize: 0,
        shopId: 0,
        endDate: "",
        fansUserId: 0,
        lockType: "",
        roleId: 0,
        startDate: "",
        userId: 0,
      },
    };
  },
  methods: {
    listData() {
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.where.shopId = this.shopId;
      this.$post(this.$api.fansDetail, this.where).then((res) => {
        this.totalData = res.data.total;
        this.where.pageNum = res.data.pages;
        this.list = res.data.records;
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    //粉丝详情
    fansDetail() {
      this.dialogTableVisible = true;
      //post 请求详情列表 赋值gridData
    },
    orderDate(obj) {
      if (obj) {
        this.order.where.startDate = obj[0];
        this.order.where.endDate = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.order.where.startDate = '';
          this.order.where.endDate = '';
        }
      });
    },
    getIconUrl(val) {
      return staticUrl + val;
    },
  },
  created() {
    this.listData();
  },
};
</script>
