<template>
  <div class="app">
    <el-form ref="form" :model="query">
      <el-row class="total-header">
        <el-col :span="span4" class="span4">
          账户余额: <span>8888.0059</span>
        </el-col>
        <el-col :span="span4" class="span4">
          惠豆数量: <span>1354</span>
        </el-col>
        <el-col :span="span4" class="span4">
          粉丝数: <span>23563</span>
        </el-col>
        <el-col :span="span4" class="span4"> 兑换订单: <span>59</span> </el-col>
        <el-col :span="span4" class="span4">
          已提现金额: <span>8567</span>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="24">
          <span class="grid-divider">基本信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="会员ID">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="等级">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="昵称">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="性别">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="生日">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="地区">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="加入时间">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="手机号">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="绑定微信">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="绑定支付宝">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="hight">
          <el-form-item label="状态">
            <el-input
              placeholder=""
              v-model="query.realName"
              :disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <span class="grid-divider">提现账号</span>
          <el-divider></el-divider>
        </el-col>
        <el-col>
          <div
            class="layadmin-contact-box"
            v-for="(row, i) in infoDetail.merchantBankList"
            :key="i"
          >
            <div class="layui-col-md4 layui-col-sm6">
              <div class="layadmin-text-center">
                <el-image>
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
            </div>
            <div class="layui-col-md8 layadmin-padding-left20 layui-col-sm6">
              <h3 class="layadmin-title">
                <strong>{{ row.bankName }}</strong>
              </h3>
              <p class="layadmin-textimg">卡号：{{ row.bankNo }}</p>
              <div class="layadmin-address">储蓄卡</div>
            </div>
          </div>
        </el-col>

        <el-col :span="24">
          <span class="grid-divider">手机号变更记录</span>
          <el-divider></el-divider>
        </el-col>
        <el-col>
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="date" label="变更时间" width="180">
            </el-table-column>
            <el-table-column prop="before" label="变更前" width="180">
            </el-table-column>
            <el-table-column prop="after" label="变更后"> </el-table-column>
          </el-table>
        </el-col>

        <el-col :span="24">
          <el-card>
            <el-button icon="el-icon-back" type="primary" @click="back">
              返回
            </el-button>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: String,
  },
  data() {
    return {
      infoDetail: {},
      span: 6,
      span4: 4,
      disabled: true,
      query: {},
      tableData: [
        {
          date: "2016-05-02",
          before: "13715161516",
          after: "13455556666",
        },
        {
          date: "2016-05-04",
          before: "13715161516",
          after: "15922223333",
        },
        {
          date: "2016-05-01",
          before: "13715161516",
          after: "18688889999",
        },
        {
          date: "2016-05-03",
          before: "13715161516",
          after: "18055556666",
        },
      ],
    };
  },
  methods: {
    handleClick(tab) {
      this[tab.name + "Is"] = true;
      if (tab.name != this.oldTab) {
        this[this.oldTab + "Is"] = false;
        this.oldTab = tab.name;
      }
    },
    infoData() {
      this.$get(this.$api.systemShopDetail + "/" + this.id, this.whale).then(
        (res) => {
          this.infoDetail = res.data;
        }
      );
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
  },
  created() {
    // this.infoData();
  },
};
</script>
<style lang="scss" scoped>
.total-header {
  margin-bottom: 0px !important;
  margin-top: 15px;

  .el-col {
    margin-right: 15px;
    :last-child {
      margin-right: 0px;
    }
  }
}
.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.el-tab-pane {
  padding-top: 10px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-divider--horizontal {
  margin: 12px 0px 12px 0px;
}

.sub-title {
  min-height: 26px;
}

.el-imag {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 178px;
}

.el-imag:hover {
  border-color: #409EFF;
}

.el-icon-picture-outline {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.layadmin-contact-box {
  width: 300px;
  display: inline-block;
  border: 1px solid #e7eaec;
  padding: 40px 0 40px 0;
}

.layadmin-text-center {
  text-align: center;
}

.layadmin-text-center .el-image {
  max-width: 40px;
  border-radius: 50%;
  margin-top: 5px;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.el-card {
  text-align: center;
}

.header-form {
  height: 40px;
  padding: 10px 0 10px 0;
}

.hight {
  height: 90px;
}

.span4 {
  background: rgb(217, 236, 255);
  font-size: 16px;
  padding: 30px 0;
  text-align: center;
  vertical-align: center;

  span {
    font-size: 18px;
  }
}
</style>
