<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="类型">
            <el-select v-model="where.queryType" placeholder="类型">
              <el-option label="全部" value=""></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间">
            <el-date-picker
              v-model="date"
              @change="orderDate" @input="immediUpdate"
              type="monthrange"
              range-separator="~"
              value-format="yyyy-MM"
              format="yyyy-MM"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
              <i class="el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>

      <div class="admin_main_block_right">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="dialogVisible = true"
              >修改惠豆
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" disabled icon="el-icon-tickets"
              >导出数据
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        prop="orderNo"
        label="交易/订单编号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="reasonsType"
        label="类型"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.reasonsType == 1"
            >消费返悦豆</el-tag
          >
          <el-tag type="warning" v-else-if="scope.row.reasonsType == 2"
            >兑换商品</el-tag
          >
          <el-tag type="danger" v-else-if="scope.row.reasonsType == 3"
            >平台消息补贴</el-tag
          >
          <el-tag type="danger" v-else-if="scope.row.reasonsType == 4"
            >购买VIP权益</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="reasonsTypeText"
        label="名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="expenditureAmount"
        label="消费金额"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop=""
        label="惠豆比例"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop=""
        label="获取惠豆"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="quantity"
        label="惠豆消耗"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop=""
        label="惠豆结余"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="createDate"
        label="时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="说明"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
        @current-change="currentChange"
        @size-change="handleSizeChange"
        background
        :total="totalData"
        :page-size="pageSize"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
    <dialogs @dialog="getDialog" v-if="dialogVisible" :id="id"></dialogs>
  </div>
</template>
<script>
import dialogs from "@/components/admin/members/user/editHuidou.vue";
export default {
  components: {
    dialogs,
  },
  props: {
    shopId: String,
  },
  data() {
    return {
      list: [],
      date: "",
      dialogVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      where: {
        pageNum: 0,
        pageSize: 0,
        shopId: 0,
        categoryCode: "",
        createDateEnd: "",
        createDateStart: "",
        keyWord: "",
        shopAreaCode: "",
        userId: 0,
        queryType: "",
      },
    };
  },
  methods: {
    listData() {
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.where.shopId = this.shopId;
      this.$post(this.$api.fenRunRecord, this.where).then((res) => {
        this.totalData = res.data.total;
        this.where.pageNum = res.data.pages;
        this.list = res.data.records;
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    //粉丝详情
    fansDetail() {
      // console.log("fansDetail");
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startDate = '';
          this.where.endDate = '';
        }
      });
    },
    exportData() {
      // console.log("exportData");
    },
    getDialog(val) {
      this.dialogVisible = val;
    },
  },
  created() {
    this.listData();
  },
};
</script>
